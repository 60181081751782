import {Controller} from "@hotwired/stimulus"
import {Elm} from '../../elm/Spinner.elm'

/**
 * Spinner controller shows the nice animated spinner in the controlled element.
 *
 * It applies default CSS classes to the controlled element to position it as
 * the spinner target. You might not want that, e.g. for dialogs -> pass
 * a non-empty data-spinner-target-class attribute.
 *
 * Other controllers can show a spinner by adding the controller attribute to
 * the spinner container & hide it by removing the controller attribute.
 */
export default class extends Controller {
  static classes = ['target']
  static targets = ['overlay'];

  connect() {
      this.element.classList.add(...this._classes());

      // double wrapping is for Elm's benefit - it takes over its wrapper
      // completely and removes any classes applied directly to it
      this.spinnerContainer = document.createElement('div');
      const spinnerContainer2 = document.createElement('div');
      this.spinnerContainer.appendChild(spinnerContainer2);

      this.element.appendChild(this.spinnerContainer);
    const app = Elm.Spinner.init({
      node: spinnerContainer2,
      flags: {}
    });
    if (null) {
      null(app, {});
    }

    this.setHeightHandler = this._setHeight.bind(this);
    this.element.addEventListener('spinner:setHeight', this.setHeightHandler)
  }

  disconnect() {
      this.element.classList.remove(...this._classes());
      if (this.spinnerContainer) {
          this.spinnerContainer.remove();
      }
      this.element.removeEventListener('spinner:setHeight', this.setHeightHandler);
  }

  _classes() {
    if (this.hasTargetClass) {
      return this.targetClasses;
    } else {
      return ['relative'];
    }
  }

  _setHeight(e) {
    if (e.detail && this.hasOverlayTarget) {
      this.overlayTarget.style.height = `${e.detail.height}px`
    }
  }
}