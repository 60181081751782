import {Controller} from "@hotwired/stimulus"
import {formSerialization} from "./mixins/form_serialization";
import {replaceByFetch} from "./mixins/replace_by_fetch";

/**
 * TurboFrameController is a poor man's version of Turbo Frames
 * https://turbo.hotwired.dev/handbook/frames.
 *
 * Any link click within the controlled element is captured and sent async,
 * the returned HTML replaces the whole frame.
 *
 * The controller listens for turbo-frame:reload events. When that happens and
 * it has the `src` value, it reloads itself from it.
 *
 * `turbo-frame-remove-on-empty-response-value="true"` will cause the controlled
 * element to be fully removed from DOM when the response is empty, e.g. when
 * an object is deleted.
 */
export default class extends Controller {
  static values = {
    src: String,
    removeOnEmptyResponse: Boolean
  }

  connect() {
    super.connect();
    formSerialization(this);
    replaceByFetch(this);
    this.element.addEventListener('click', (e) => {
      if (!this.scope.containsElement(e.target)) {
        return;
      }

      if (e.target.tagName === 'A' || e.target.closest('a')) {
        this.handleLink(e);
      } else if (e.target.tagName === 'INPUT' && e.target.type === 'submit') {
        this.handleform(e)
      }
    });
    this.element.addEventListener('turbo-frame:reload', (e) => {
      this.reload()
    });
  }

  handleform(e){
    e.preventDefault();
    let form = e.target.closest('form');
    if (!form) {
      return;
    }

    let url = form.action;
    const data = new FormData();
    this.collectInputValues(form, data)

    this.showSpinner();

    this.replaceByFetch(url, this.formMethod(form), {data: data});
  }

  formMethod(form) {
    const input = form.querySelector('[name=_method]');
    if (input) {
      return input.value.toUpperCase();
    }

    return 'POST';
  }

  handleLink(e) {
    const link = e.target.closest('a');
    if (link.dataset['turbo'] === 'false') {
      return;
    }

    e.preventDefault();
    if (link.dataset['turboFrameConfirm']) {
      if (!confirm(link.dataset['turboFrameConfirm'])) {
        return;
      }
    }

    this.replaceByFetch(link.href, link.dataset['turboFrameMethod'] || 'GET');
  }

  reload() {
    if (!this.srcValue) {
      return;
    }

    this.replaceByFetch(this.srcValue, 'GET');
  }
}