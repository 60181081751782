import syncMenus from "./sync-menus";
import sideBarLinks from "./sidebar-links";
import catchBeErrors from "./catch-be-errors";

const turboHelpers = () => {
  //catchBeErrors();
  syncMenus();
  //sideBarLinks();
}

export default turboHelpers;

